import { BreakpointObserver } from '@angular/cdk/layout';
import { DatePipe, isPlatformBrowser } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// import { NbCalendarRange } from '@nebular/theme';
import { fuseAnimations } from 'src/app/animations';
import { HomePageData } from 'src/app/models/homepage.types';
import { SeoService } from 'src/app/seo.service';
import { HomeService } from './home.service';
import { MxvRangepickerComponent } from 'src/app/shared/components/mxv-rangepicker/mxv-rangepicker.component';
import { PopupPanelService } from 'src/app/shared/components/popup-panel/popup-panel.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',

  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
  @ViewChild('rangePickerTrigger') private _rangePickerTrigger: HTMLElement;

  public isBrowser = isPlatformBrowser(this.platformId);
  showCalendar = false;

  public btnDatesValue = 'Select Your Dates';
  loaded = false;
  private dateRange = '';

  myForm: UntypedFormGroup;
  useMobileDates = false;

  homePageInfo: {
    posts: any;
    featured: any;
  };

  homePageInfo$: Observable<HomePageData>;

  selectedDates: Date[];

  // range: NbCalendarRange<Date>;
  nonMobileRange = '';

  adults: number;
  children: number;
  infants: number;
  totalGuests: number;
  checkInCheckOut: string;
  searchLocation = 'Anywhere';
  searchModel = '';
  paropida = '';
  placeId = '';

  showMyElement = false;

  randomPhotoNumber = 0;

  minDate: any;
  blocked: any;
  showOptions: boolean;

  smallDevice = false;
  heroImage = '';

  startDate: Date;
  endDate: Date;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    @Inject(PLATFORM_ID) private platformId: {},

    private _homeService: HomeService,
    private seoService: SeoService,
    private router: Router,
    private observer: BreakpointObserver,
    private _changeDetectorRef: ChangeDetectorRef,
    private _popupPanelService: PopupPanelService
  ) {
    this.homePageInfo = {
      posts: [],
      featured: [],
    };
    this.checkInCheckOut = '';
    this.adults = 0;
    this.children = 0;
    this.infants = 0;
    this.totalGuests = 0;
    this.showOptions = false;

    this.randomPhotoNumber = this.randomInteger(0, 2);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterContentChecked(): void {
    this.loaded = true;
  }
  ngAfterViewInit(): void {}

  ngOnInit() {
    this.homePageInfo$ = this._homeService.homePageData$;

    this.myForm = new UntypedFormGroup({
      location: new UntypedFormControl('Protaras'),
      email: new UntypedFormControl(''),
      message: new UntypedFormControl(''),
    });

    this.seoService.setTitle(
      'Holiday Rentals, Luxury Villas, Apartments, Vacation - Myexclusive Villas'
    );
    this.seoService.setCanonicalURL();
    this.seoService.generateTags({
      title:
        'Holiday Rentals, Luxury Villas, Apartments, Vacation - Myexclusive Villas',
      description:
        'Uncover the best accomodations and experience your vacation in style. Holiday rentals, apartments and villas.  ',
      image: 'https://myexclusivevillas.com/storage/sliders/SPY_1856.jpg',
      slug: '',
    });

    this.seoService.updateTag({
      name: 'description',
      content:
        'Uncover the best accomodations and experience your vacation in style. Holiday rentals, apartments and villas.',
    });

    this.observer
      .observe('(max-width: 784px)')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        this.smallDevice = result.matches;
        if (this.isBrowser) {
          this.getHomePageInfo();
        }
      });
  }

  onSearch(key): void {
    this.router.navigateByUrl(
      `/search/${key}/rentals?dates=${this.dateRange}&adults=${this.adults}&children=${this.children}&infants=${this.infants}&place_id=${this.placeId}`
    );
  }

  getHomePageInfo(): void {
    // Get all info for home page

    this._homeService
      .showHomeInfo(this.smallDevice)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  onSubmit(): void {
    this.router.navigateByUrl(
      `/search/${this.searchLocation}/rentals?dates=${this.dateRange}&adults=${this.adults}&children=${this.children}&infants=${this.infants}&place_id=${this.placeId}`
    );
  }

  /**
   * On Connection name changed
   *
   * @param newName
   */

  onPlacesBoxChange(newPlace): void {
    this.searchLocation = newPlace.formatted_address;
    this.searchLocation = this.searchLocation
      .replace(/\s+/g, '-')
      .replace(/,+/g, '-');
    this.placeId = newPlace.place_id;
  }

  onInputValueChanged(e): void {
    this.searchLocation = e;
    this.searchLocation = this.searchLocation
      .replace(/\s+/g, '-')
      .replace(/,+/g, '-');
  }

  public changeAdults(increase: boolean): number {
    if (increase) {
      this.adults += 1;
      return this.adults + this.children + this.infants;
    }

    this.adults = this.adults - 1 <= 0 ? 0 : this.adults - 1;

    return this.adults + this.children + this.infants;
  }
  public changeChildren(increase: boolean): number {
    if (increase) {
      this.children += 1;
      return this.adults + this.children + this.infants;
    }

    this.children = this.children - 1 <= 0 ? 0 : this.children - 1;

    return this.adults + this.children + this.infants;
  }
  public changeInfants(increase: boolean): number {
    if (increase) {
      this.infants += 1;
      return this.adults + this.children + this.infants;
    }

    this.infants = this.infants - 1 <= 0 ? 0 : this.infants - 1;

    return this.adults + this.children + this.infants;
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  openRangePicker(): void {
    const popupPanelRef = this._popupPanelService.open(
      MxvRangepickerComponent,
      {
        data: {
          smallDevice: this.smallDevice,
        },
      },
      this._rangePickerTrigger
    );

    popupPanelRef.afterClosed().subscribe((data) => {
      // Subscription runs after the close event
      if (data && data !== 'fromX') {
        this.onDatesSelectedT(data);
      }
    });
  }

  onCalendarVisibilityChanged(val: { visible: boolean }): void {
    this.showCalendar = val.visible;
  }
  onDatesSelectedT(dt: { from: string; to: string }) {
    // this.searchInformation.dates = dt.from + '_' + dt.to;
    this.dateRange = `${dt.from}_${dt.to}`;
    // tslint:disable-next-line:quotemark
    this.startDate = new Date(dt.from);

    // tslint:disable-next-line:quotemark
    this.endDate = new Date(dt.to);

    // this.filter('dates', this.searchInformation.dates);
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
