<section class="d-flex align-items-center dark-overlay bg-cover heroHome">
  <div class="container py-5 py-lg-7 text-white overlay-content text-center">
    <div class="row">
      <div class="col-xl-10 mx-auto">
        <div style="height: 150px" class="d-none d-lg-block"></div>
        <h1 class="display-4 fw-bold text-shadow">Vacations In Style</h1>
        <p class="text-lg text-shadow">
          Uncover the best accomodations in Cyprus.
        </p>
      </div>
    </div>
  </div>
</section>
<div class="container">
  <div class="search-bar rounded p-3 p-lg-4 position-relative mt-n5 z-index-20">
    <form #myForm>
      <div class="row">
        <div class="col-lg-4 d-flex align-items-center form-group">
          <div class="input-label-absolute input-label-absolute-right w-100">
            <label for="location" class="label-absolute">
              <i class="fas fa-search-location"></i>
              <div class="sr-only">Location</div>
            </label>
            <app-gautocomplete
              (click)="showOptions = false"
              (placesBoxChanged)="onPlacesBoxChange($event)"
              (inputValueChanged)="onInputValueChanged($event)"
              [types]="['geocode', 'establishment']"
              class="form-control border-0 shadow-0"
            >
            </app-gautocomplete>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 d-flex align-items-center form-group">
          <div class="input-label-absolute input-label-absolute-right w-100">
            <label for="location" class="label-absolute">
              <div class="sr-only">Dates:</div>
            </label>

            <label for="location" class="label-absolute">
              <i class="far fa-calendar-alt"></i>
              <div class="sr-only">Dates:</div>
            </label>

            <input
              name="calendarTrigger"
              #rangePickerTrigger
              (click)="openRangePicker()"
              type="text"
              placeholder="Your Dates"
              class="border-0 form-control shadow-0"
              style="background-color: white"
              readonly
              [value]="
                (startDate ? (startDate | date : 'dd-MMM y') : '') +
                (startDate && endDate ? ' - ' : '') +
                (endDate ? (endDate | date : 'dd-MMM y') : '')
              "
            />
          </div>
        </div>

        <div class="col-md-6 col-lg-2 d-flex align-items-center form-group">
          <div class="input-label-absolute input-label-absolute-right w-100">
            <label for="location" class="label-absolute"
              ><i class="fa fa-user-friends"></i>
              <div class="sr-only">Guests:</div>
            </label>
            <input
              (focus)="showOptions = !showOptions"
              type="text"
              class="form-control border-0"
              placeholder="{{ totalGuests }} Guests"
            />

            <div
              *ngIf="showOptions"
              (blur)="showOptions = false"
              class="filterOptions dropdown-menu show dpTolis paropida"
            >
              <div class="row">
                <div class="col-12 m-3">
                  <strong>* Select Your Guests below</strong>
                </div>
              </div>
              <div class="row minplus-row">
                <div class="col-6">{{ adults }} Adults</div>
                <div class="col-6">
                  <div
                    class="btn btn-items btn-items-decrease"
                    style="margin-right: 8px"
                    (click)="totalGuests = changeAdults(false)"
                  >
                    -
                  </div>
                  <div
                    class="btn btn-items btn-items-increase"
                    (click)="totalGuests = changeAdults(true)"
                  >
                    +
                  </div>
                </div>
              </div>
              <div class="tol-divider"></div>
              <div class="row minplus-row">
                <div class="col-6">{{ children }} Children</div>
                <div class="col-6">
                  <div
                    class="btn btn-items btn-items-decrease"
                    style="margin-right: 8px"
                    (click)="totalGuests = changeChildren(false)"
                  >
                    -
                  </div>
                  <div
                    class="btn btn-items btn-items-increase"
                    (click)="totalGuests = changeChildren(true)"
                  >
                    +
                  </div>
                </div>
              </div>
              <div class="tol-divider"></div>
              <div class="row minplus-row">
                <div class="col-6">{{ infants }} Infants</div>
                <div class="col-6">
                  <div
                    class="btn btn-items btn-items-decrease"
                    style="margin-right: 8px"
                    (click)="totalGuests = changeInfants(false)"
                  >
                    -
                  </div>
                  <div
                    class="btn btn-items btn-items-increase"
                    (click)="totalGuests = changeInfants(true)"
                  >
                    +
                  </div>
                </div>
              </div>
              <div class="tol-divider"></div>
              <div class="row" style="padding: 12px">
                <div class="col-12">
                  <button class="btn btn-primary" (click)="showOptions = false">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 form-group mb-0">
          <button
            type="button"
            class="btn btn-secondary w-100 h-100"
            (click)="onSubmit()"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<section *ngIf="!smallDevice" class="py-4">
  <div class="container">
    <div class="text-center pb-lg-4">
      <h2 class="mb-4">Booking with us is easy</h2>
    </div>
    <div class="row">
      <div class="col-lg-4 mb-3 mb-lg-0 text-center">
        <div class="px-0 px-lg-3">
          <div class="icon-rounded bg-secondary-light text-secondary mb-3">
            <i class="fas fa-map-marked-alt fa-2x"></i>
          </div>
          <h3 class="h5">Find the perfect rental</h3>
          <p class="text-muted">
            - Search our website to find the best rental for your vacation.
            <br />
            - Use the website chat to speak with one of our rental specialists
            to get help finding the perfect rental
          </p>
        </div>
      </div>
      <div class="col-lg-4 mb-3 mb-lg-0 text-center">
        <div class="px-0 px-lg-3">
          <div class="icon-rounded bg-secondary-light text-secondary mb-3">
            <i class="far fa-credit-card fa-2x"></i>
          </div>
          <h3 class="h5">Book with confidence</h3>
          <p class="text-muted">
            Meaning: Our Dedicated team is always there to answer any questions
            before and after booking, during your stay and after your vacation
            is over.<br />
            The data transmitted is always Encrypted for your safety
          </p>
        </div>
      </div>
      <div class="col-lg-4 mb-3 mb-lg-0 text-center">
        <div class="px-0 px-lg-3">
          <div class="icon-rounded bg-secondary-light text-secondary mb-3">
            <i class="far fa-grin-hearts fa-2x"></i>
          </div>
          <h3 class="h5">Best Price Guaranteed</h3>
          <p class="text-muted">
            Found a lower price elsewhere? No problem! If you find a lower price
            elsewhere within 24 hours of booking, we will match the lower rate
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="py-3 bg-gray-100">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-8">
        <h2>Featured Rentals</h2>
      </div>
      <div class="col-md-4 d-lg-flex align-items-center justify-content-end">
        <a
          [routerLink]="[
            '/search/Anywhere/rentals?dates=&adults=2&children=0&infant=0&place_id='
          ]"
          class="text-muted text-sm"
        >
          See All Rentals<i class="fas fa-angle-double-right ms-2"></i
        ></a>
      </div>
    </div>

    <div class="row">
      <!-- blog item-->
      <ng-container *ngIf="homePageInfo$ | async as hp">
        <ng-container *ngIf="hp.featured.length">
          <div
            class="col-lg-3 col-sm-6 mb-4 hover-animate"
            *ngFor="let featuredProperty of hp.featured"
          >
            <div class="w-100 h-100 hover-animate">
              <div class="card h-100 border-0 shadow">
                <div class="card-img-top overflow-hidden gradient-overlay">
                  <img
                    loading="lazy"
                    data-src="https://myexclusivevillas.com/storage/p/{{
                      featuredProperty.id
                    }}/{{ featuredProperty.photo }}"
                    alt="{{ featuredProperty.t }}{{ featuredProperty.fn }}"
                    class="img-fluid"
                  />

                  <a
                    [routerLink]="[
                      '/accomodation/' +
                        featuredProperty.id +
                        '/' +
                        featuredProperty.slug
                    ]"
                    class="tile-link"
                  ></a>
                  <div class="card-img-overlay-bottom z-index-20">
                    <div class="media text-white text-sm align-items-center">
                      <div class="media-body"></div>
                    </div>
                  </div>
                  <div class="card-img-overlay-top text-end"></div>
                </div>
                <div class="card-body d-flex align-items-center">
                  <div class="w-100">
                    <h6 class="card-title">
                      <a
                        [routerLink]="[
                          '/accomodation/' +
                            featuredProperty.id +
                            '/' +
                            featuredProperty.slug
                        ]"
                        class="text-decoration-none text-dark"
                        >{{ featuredProperty.t }}</a
                      >
                    </h6>
                    <div class="d-flex card-subtitle mb-3">
                      <p class="flex-grow-1 mb-0 text-muted text-sm">
                        {{ featuredProperty.ty }}
                      </p>
                      <p class="flex-shrink-1 mb-0 card-stars text-xs text-end">
                        <app-star-rating
                          [rating]="featuredProperty.avg"
                        ></app-star-rating>
                      </p>
                    </div>
                    <p class="card-text text-muted">
                      <span class="h4 text-primary">{{
                        featuredProperty.avga
                          | currency : "EUR" : "symbol" : "1.0-0"
                      }}</span>
                      average per night
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>

<!-- Our picks section-->
<section class="py-3">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-8">
        <p class="subtitle text-primary">Hot Locations</p>
        <h2>Popular Locations</h2>
      </div>
      <div class="col-md-4 d-md-flex align-items-center justify-content-end">
        <a href="category.html" class="text-muted text-sm">
          See all Locations<i class="fas fa-angle-double-right ms-2"></i
        ></a>
      </div>
    </div>
    <div class="row">
      <div class="d-flex align-items-lg-stretch mb-4 col-lg-8">
        <div
          style="
            background: center center
              url(/assets/Nissi_26_03_19_005-n640x480.jpg) no-repeat;
            background-size: cover;
          "
          class="card shadow-lg border-0 w-100 border-0 hover-animate"
        >
          <a [routerLink]="'/areas/Ayia-Napa'" class="tile-link"> </a>
          <div
            class="d-flex align-items-center h-100 text-white justify-content-center py-6 py-lg-7"
          >
            <h3 class="text-shadow text-uppercase mb-0">Ayia Napa</h3>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-lg-stretch mb-4 col-lg-4">
        <div
          style="
            background: center center
              url(/assets/FigTreeBay_smSlider-n620x440.jpg) no-repeat;
            background-size: cover;
          "
          class="card shadow-lg border-0 w-100 border-0 hover-animate"
        >
          <a [routerLink]="'/areas/Protaras'" class="tile-link"> </a>
          <div
            class="d-flex align-items-center h-100 text-white justify-content-center py-6 py-lg-7"
          >
            <h3 class="text-shadow text-uppercase mb-0">Protaras</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="d-flex align-items-lg-stretch mb-4 col-lg-4"
        *ngIf="!smallDevice && loaded"
      >
        <div
          style="
            background: center center
              url(/assets/AyiaTheklaBeach_4-n700x480.jpg) no-repeat;
            background-size: cover;
          "
          class="card shadow-lg border-0 w-100 border-0 hover-animate"
        >
          <a [routerLink]="'/areas/Ayia-Thekla'" class="tile-link"> </a>
          <div
            class="d-flex align-items-center h-100 text-white justify-content-center py-6 py-lg-7"
          >
            <h3 class="text-shadow text-uppercase mb-0">Ayia Thekla</h3>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-lg-stretch mb-4 col-lg-4"
        *ngIf="!smallDevice && loaded"
      >
        <div
          style="
            background: center center
              url(/assets/Limassol_marina_1_-n700x480.jpg) no-repeat;
            background-size: cover;
          "
          class="card shadow-lg border-0 w-100 border-0 hover-animate"
        >
          <a [routerLink]="'/areas/Larnaca'" class="tile-link"> </a>
          <div
            class="d-flex align-items-center h-100 text-white justify-content-center py-6 py-lg-7"
          >
            <h3 class="text-shadow text-uppercase mb-0">Larnaca</h3>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-lg-stretch mb-4 col-lg-4">
        <div
          style="
            background: center center url(/assets/Coral_Bay_sm-n700x480.jpg)
              no-repeat;
            background-size: cover;
          "
          class="card shadow-lg border-0 w-100 border-0 hover-animate"
        >
          <a [routerLink]="'/areas/coral-bay'" class="tile-link"> </a>
          <div
            class="d-flex align-items-center h-100 text-white justify-content-center py-6 py-lg-7"
          >
            <h3 class="text-shadow text-uppercase mb-0">Coral Bay</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Divider Section-->
<!-- <section class="py-7 position-relative dark-overlay">
  <img src="/assets/DJI_0494-n1300x500.jpg" alt="" class="bg-image" />
  <div class="container">
    <div class="overlay-content text-white py-lg-5">
      <h3 class="display-3 fw-bold text-serif text-shadow mb-5">
        Photos from our Guests
      </h3>
      <a href="category-rooms.html" class="btn btn-light">View Galleries</a>
    </div>
  </div>
</section> -->
<section class="py-6 bg-gray-100">
  <div class="container">
    <div class="row mb-5">
      <div class="col-md-8">
        <p class="subtitle text-secondary">
          News, Events related to Travel and Tourism
        </p>
        <h2>Travel blog</h2>
      </div>
      <div class="col-md-4 d-md-flex align-items-center justify-content-end">
        <a href="#" class="text-muted text-sm">
          See all articles<i class="fas fa-angle-double-right ms-2"></i
        ></a>
      </div>
    </div>
    <div class="row">
      <!-- blog item-->
      <ng-container *ngIf="homePageInfo$ | async as hp">
        <ng-container *ngIf="hp.posts.length">
          <div
            class="col-lg-4 col-sm-6 mb-4 hover-animate"
            *ngFor="let post of hp.posts"
          >
            <div class="card shadow border-0 h-100">
              <a [routerLink]="['/blog/' + post.id + '/' + post.slug]"
                ><img
                  src="https://myexclusivevillas.com/storage/blog_images/{{
                    post.id
                  }}/{{ post.image }}"
                  alt="{{ post.title }}"
                  class="img-fluid card-img-top"
                  width="340"
                  height="220"
                  loading="lazy"
              /></a>
              <div class="card-body">
                <h5 class="my-2">
                  <a
                    [routerLink]="['/blog/' + post.id + '/' + post.slug]"
                    class="text-dark"
                    >{{ post.title }}
                  </a>
                </h5>
                <p class="text-gray-500 text-sm my-3">
                  <i class="far fa-clock me-2"></i>{{ post.post_at | date }}
                </p>
                <p class="my-2 text-muted text-sm"></p>
                <a
                  [routerLink]="['/blog/' + post.id + '/' + post.slug]"
                  class="btn btn-link ps-0"
                  >Read more<i class="fa fa-long-arrow-alt-right ms-2"></i
                ></a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</section>
<!--  Templates -->
