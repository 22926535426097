import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { User } from '../models/user';
import { isPlatformBrowser } from '@angular/common';
import { EnvService } from '../shared/services/env/env.service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { TokenService } from './token.service';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private loggedInUser: User;

  constructor(
    private http: HttpClient,
    private token: TokenService,
    @Inject(PLATFORM_ID) private platformId: {},
    private env: EnvService,
    private storage: StorageMap
  ) {}

  /** POST LOGIN */
  public login(data: {}): Observable<LoginData> {
    return this.http
      .post<LoginData>(`${environment.endpoints.login}`, data, httpOptions)
      .pipe(
        tap((loginData: LoginData) => this.handleLoggedInUser(loginData)),
        catchError(this.handleLoginError<ErrorI>('Login Attempt'))
      );
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    repeatpassword: string;
    country: string;
    phone: string;
  }): Observable<any> {
    return this.http.post<any>(`${environment.endpoints.signup}`, user).pipe();
  }

  /**
   *
   * @param _code string
   * @param _v string
   * @returns Observable
   */
  verifyCode(vc: { code: string; v: string }): Observable<any> {
    return this.http
      .post<any>(`${environment.endpoints.signup}/verify-code`, vc)
      .pipe();
  }

  /** POST LOGIN */
  public loginByGoogle(data: {}): Observable<LoginData> {
    return this.http
      .post<LoginData>(
        `${environment.endpoints.login}/google/callback`,
        data,
        httpOptions
      )
      .pipe(
        tap((loginData: LoginData) => this.handleLoggedInUser(loginData)),
        catchError(this.handleLoginError<ErrorI>('Login Attempt By Google'))
      );
  }

  /** POST LOGIN */
  public loginByFacebook(accessToken: string): Observable<LoginData> {
    return this.http
      .post<LoginData>(
        `${environment.endpoints.login}/facebook/callback`,
        { token: accessToken },
        httpOptions
      )
      .pipe(
        tap((loginData: LoginData) => this.handleLoggedInUser(loginData)),
        catchError(this.handleLoginError<ErrorI>('Login Attempt By Facebok'))
      );
  }

  public logout(): Observable<any> {
    return this.http
      .get(`${environment.endpoints.logout}/auth/logout`, httpOptions)
      .pipe(
        tap((results) => this.token.remove()),
        catchError(this.handleLoginError<ErrorI>('Login Attempt'))
      );
  }

  /** POST REQUEST-PASSWORD-RESET */
  public requestResetLink(data: string): Observable<{}> {
    return this.http
      .post<string>(
        `${environment.endpoints.api}/reset-password-request`,
        data,
        httpOptions
      )
      .pipe(
        // tap((response: string) => this.log(response)),  // success
        catchError(
          this.handleResetRequestError<ErrorI>('reset-password-request')
        ) // error
      );
  }

  /** POST REQUEST-PASSWORD-RESET */
  public changePassword(data: {}): Observable<{}> {
    return this.http
      .post<string>(
        `${environment.endpoints.api}/reset-password`,
        data,
        httpOptions
      )
      .pipe(
        // tap((response: string) => this.log(response)),  // success
        catchError(this.handleResetRequestError<ErrorI>('reset-password')) // error
      );
  }

  /**
   * After the user has successfully loged in we save the info to localstorage
   * @param loginData
   */
  private handleLoggedInUser(loginData: LoginData): void {
    if (loginData.access_token && loginData.user) {
      if (isPlatformBrowser(this.platformId)) {
        loginData.user.avatar = loginData.avatar;
        let timeObject = new Date();
        loginData.user.expires = Math.ceil(
          timeObject.getTime() / 1000 + Number(loginData.expires_in)
        );
        localStorage.setItem('currentUser', JSON.stringify(loginData.user));
        // this.storage.set('currentUser', loginData.user).subscribe({
        //   next: () => {},
        // });
      }
    }
  }

  /** Error Handling */

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

  private handleResetRequestError<T>(operation = 'operation', result?: T): any {
    return (error: ErrorI): Observable<any> => {
      // Throw An error to the app to know that the request returned an error
      return throwError(error);
    };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */

  private handleLoginError<T>(operation = 'operation', result?: T): any {
    return (error: ErrorI): Observable<any> => {
      // Throw An error to the app to know that the login returned an error
      return throwError({
        status: error.status,
        message:
          error.status === 401 ? 'Wrong Email or Password' : error.message,
      });
    };
  }

  private log(message: string): void {}
}

export interface LoginData {
  status: string;
  message: string;
  error: {};
  headers: {};
  name: string;
  ok: boolean;
  access_token: string;
  user: User;
  avatar: string;
  expires_in: number;
}

export interface ErrorI {
  message: string;
  status: number;
  statusText: string;
  data: string;
}
