import { isPlatformBrowser, Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginComponent } from 'src/app/dialogs/login/login.component';
import { SignupDialog } from 'src/app/dialogs/signup/signup.dialog';
import { SearchInfo } from 'src/app/models/search-info';
import { User } from 'src/app/models/user';
import { AuthcheckService } from 'src/app/services/authcheck.service';
import { FavoriteService } from 'src/app/services/favorite.service';
import { TokenService } from 'src/app/services/token.service';
import { TolisDialogService } from 'src/app/dialogs/tolis-dialog/tolis-dialog.service';

import { EnvService } from 'src/app/shared/services/env/env.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  // @ViewChild('loginRef', { static: true }) loginElement: ElementRef;
  @ViewChild('searchboxTop', { static: true }) searchboxTop: ElementRef;
  @ViewChild('openSignUpBtn') private _origin: ElementRef;
  @Input() showBack: boolean;

  @Output() valueChange = new EventEmitter();

  searchForm: UntypedFormGroup;
  searchInformation: SearchInfo = new SearchInfo();
  queryParamsSubscription: Subscription;

  auth2: any;
  apiUrl: string;
  favorites = [];
  previousUrl = '';

  public loggedIn: boolean;
  public user = new User({});

  // Private
  private unsubscribeAll: Subject<any> = new Subject<any>();
  private unsubscribeFavs: Subject<any> = new Subject<any>();

  constructor(
    private authCheckService: AuthcheckService,
    private tokenService: TokenService,
    private storage: StorageMap,
    private favoriteService: FavoriteService,
    private router: Router,
    private location: Location,
    @Inject(PLATFORM_ID) private platformId: {},
    private formBuilder: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _tolisDialogService: TolisDialogService
  ) {
    this.previousUrl = '/';
    // !router.getCurrentNavigation().previousNavigation ? '/' : router.getCurrentNavigation().previousNavigation.initialUrl.toString();

    this.showBack = false;
  }

  onBackClick(): void {
    if (this.previousUrl === '/') {
      this.router.navigateByUrl(this.previousUrl);
    } else {
      this.location.back();
    }
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchbox: [''],
    });

    this.apiUrl = environment.endpoints.api;

    if (isPlatformBrowser(this.platformId)) {
      // Set the logged in status
      const self = this;
      this.storage.get('favorites').subscribe((data: any[]) => {
        this.favorites = data ? data : [];
      });

      this.favoriteService.observer
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((value) => {
          this.storage.get('favorites').subscribe((data: any[]) => {
            this.favorites = data ? data : [];
          });
        });

      this.authCheckService.authStatus.subscribe(
        (value) => {
          if (!this.user) {
            this.user = new User({});
          }

          this.loggedIn = value;

          if (this.loggedIn) {
            const user = JSON.parse(localStorage.getItem('currentUser'));
            user.avatarUrl =
              environment.endpoints.storage +
              '/avatars/' +
              user.id +
              '/avatar.jpg';

            this.user = user;
          }
        },
        (error) => console.log('error', error)
      );
    }
  }

  /**
   * On destroy
   */

  onSubmit(): void {
    let fuckit;
    let params = this.route.snapshot.queryParams;
    const paramString = Object.keys(params)
      .map((key) => {
        return key + '=' + params[key];
      })
      .join('&');

    this.router.navigateByUrl(
      `/search/${this.searchForm.value.searchbox}/rentals?${paramString}`
    );
    this.valueChange.emit(this.searchForm.value.searchbox);
  }

  onPlacesBoxChange(e): void {
    this.valueChange.emit(e.name);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next(null);
    this.unsubscribeAll.complete();
  }
  openLogin(): void {
    const tolisDialogRef = this._tolisDialogService
      .open(LoginComponent, {
        data: {
          selectedProducts: [],
          multiple: true,
        },
      })
      .afterClosed()
      .subscribe((message) => {});
  }

  openSignupModal(): void {
    const tolisDialogRef = this._tolisDialogService.open(SignupDialog, {
      data: {
        animation: true,
      },
    });

    tolisDialogRef.afterClosed().subscribe((message) => {
      // Subscription runs after the close event

      if (message !== 'fromx') {
        this.ref.detectChanges();
      }
      if (message === 'signup') {
      }
    });
  }

  logout(event: MouseEvent): void {
    // event.preventDefault();
    this.tokenService.remove();
    this.authCheckService.changeAuthStatus(false);
  }
}
